export default {
  formatToHiddenEmail (correo) {
    if (correo) {
      // Obtener la parte antes del símbolo '@' (dejando algunos caracteres sin ocultar)
      let partes = correo.split("@");
      let nombreUsuario = partes[0];
      let dominio = partes[1];
    
      // Calcula la mitad de la longitud del nombre de usuario, redondeando hacia abajo
      let mitadLongitud = Math.floor(nombreUsuario.length / 2);
    
      // Enmascara la segunda mitad del nombre de usuario con asteriscos
      let nombreUsuarioEnmascarado = nombreUsuario.slice(0, mitadLongitud) + "*".repeat(nombreUsuario.length - mitadLongitud);
    
      return nombreUsuarioEnmascarado + "@" + dominio;
    }
    return 'xxxxxxxxxx@xxx.xxx'
  },
  
  formatToHiddenPhone (celular) {
    if (celular) {
      // Obtener los primeros dígitos (dejando los últimos 4 sin ocultar)
      let primerosDigitos = celular.slice(0, -4)
      // Obtener los últimos 4 dígitos (los que se mostrarán)
      console.log(celular)
      let ultimosDigitos = celular.slice(-4)
      // Generar una cadena con los primeros dígitos ocultos y los últimos visibles
      let numeroOculto = "x".repeat(primerosDigitos.length) + ultimosDigitos
      return numeroOculto
    }
    return 'xxxxxx'
  },
  formatCurrency(valor) {
    valor = parseFloat(valor)
    return valor.toLocaleString('es-CO', 
      { style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
  },
  fechaFormateadaLarga(fechaString) {
    const opciones = { day: 'numeric', month: 'long', year: 'numeric' };
    const fecha = new Date(fechaString);
    if(isNaN(fecha)){
      return '-'
    }
    return fecha.toLocaleDateString('es-ES', opciones);
  },
  fechaFormateada(fecha) { // Recibe objeto de tipo Date
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // getMonth() devuelve el mes desde 0 a 11, por eso se suma 1
    const año = fecha.getFullYear();

    return `${año}-${mes}-${dia}T12:00:00`;
  },
  addLeadingZeros(value, desiredLength) {
    const valueAsString = String(value);
    const zerosToAdd = desiredLength - valueAsString.length;
    if (zerosToAdd <= 0) {
        return valueAsString; // No es necesario agregar ceros
    }
    const zeros = '0'.repeat(zerosToAdd);
    return zeros + valueAsString;
  },
  removeCountryCode(number) {
    const indicativo = '57'
    if (number.startsWith(indicativo)) {
      return number.slice(indicativo.length);
    } else if(number.startsWith('+' + indicativo)){
      return number.slice('+57'.length)
    }
    return number;
  },
  addCountryCode(number) {
    const indicativo = '57'
    if (!number.startsWith(indicativo) || !number.startsWith("+"+indicativo)) {
      return `57${number}`
    } else return number
  }
}